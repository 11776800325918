<template>
    <BaseSection
        class="section--announcement-card"
        data-width="wide-content"
    >
        <AnnouncementCard :content="content" />
    </BaseSection>
</template>

<script>
import AnnouncementCard from '~/components/AnnouncmentCard/AnnouncementCard.vue';

export default {
    components: { AnnouncementCard },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },
};
</script>
